import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Hero,
} from '@components';

const TextPage = ({ data }) => {
  const { frontmatter = {} } = data.markdownRemark;

  const {
    hero,
    seo,
  } = frontmatter;

  return (
    <>
      <Hero {...hero} seo={seo} />
    </>
  );
};

TextPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
};

export default TextPage;

export const contactPageQuery = graphql`
  query TextPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          markdown
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
